
label .menu {
    position: absolute;
    right: calc(50% - 50px);
    top: -100px;
    z-index: 100;
    width: 100px;
    height: 100px;
    background: #FFF;
    border-radius: 50% 50% 50% 50%;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    box-shadow: 0 0 0 0 #FFF, 0 0 0 0 #FFF;
    cursor: pointer;
    
  }
  
  label .hamburger {
    position: absolute;
    top: 135px;
    left: 50px;
    width: 30px;
    height: 5px;
    border-radius: 5px;
    background: #444440;
    display: block;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
  }
  
  label .hamburger:after, label .hamburger:before {
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: #444440;
  }
  
  label .hamburger:before { top: -10px; }
  
  label .hamburger:after { bottom: -10px; }
  
  label input { display: none; }
  
  label input:checked + .menu {
    box-shadow: 0 0 0 100vw #FFF, 0 0 0 100vh #FFF;
    border-radius: 0;
  }
  
  label input:checked + .menu .hamburger {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    
  }
  
  label input:checked + .menu .hamburger:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    bottom: 0;
    
  }
  
  label input:checked + .menu .hamburger:before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    top: 0;
    
  }
  
  label input:checked + .menu + ul { display: block; /*show menu when active*/ }
  
  label ul {
    list-style: none;
    z-index: 200;
    position: absolute;
    top: 100px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: none; /*Hide menu when not active*/
    -webkit-transition: .25s 0s ease-in-out;
    transition: .25s 0s ease-in-out;
  }
  
  label a {
    margin-bottom: 1em;
    display: block;
    /* color: ; */
    text-decoration: none;
    
  }