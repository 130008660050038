* { box-sizing: content-box; }  

/* body */
body {
  margin: 0;
}

.App {
  background-size: cover;
  background-position: center ;
  font-family: Arial, Helvetica, sans-serif;
  text-align: -webkit-center;
}

p {
  color: #171616;
}

a {
  text-decoration: none;
  color: #444440;
}

/* The navigation component hides when active ---------------------------------------*/
.nav_active {
  display: none;
}
/* Navigation ---------------------------------------------------------------------- */
.navigation {
  float: left;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
  font-size: x-large;
  width: 100%;
  justify-content: center;
}
.navigation > a {
  color: #000000;
}

/* Home Background            ------------------------------------------------------ */
.header {
  height: 200vh;
  margin-top: 50px;
}

.container {
  clip: rect(0, auto, auto 0);
  overflow: hidden;
  position: absolute;
  height: 100vh;
  left: 0;
  width: 100%;
  -webkit-mask-image: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
          mask-image: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
}
.container_solid {
  background: white;
}
.title_wrapper {
  position: fixed;
  display: block;
  margin: auto;
  width: 100%;
  top: 50vh;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.title_wrapper h1 {
  text-align: center;
  font-size: 500%;
  text-transform: uppercase;
  font-family: serif;
  font-weight: 900;
}
.container_solid .title_wrapper h1 {
  background: url(/static/media/image1.d71cc4fe.jpg);
  background-size: cover;
  background-position: center;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color: black;
}
.container_image {  
  background-image: url(/static/media/image1.d71cc4fe.jpg);  
  background-size: cover;  
  background-position: center;  
  background-attachment: fixed;  
  /* position the second container below the first container */  
  top: 100vh;  
 }  
 .container_image .title_wrapper h1 {  
  color: white;  
 }  
 /* Scroll effect ends here */


 /* Timeline CSS -------------------------------------------------------------------- */
.timeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  min-height: 74vh;
  margin: 2em auto;
  padding: 15px 0;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background: #006E51;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

.timeline_container {
  padding: 15px 30px;
  position: relative;
  background: inherit;
  width: 50%;
}

.timeline_container.left {
  right: 27.5%;
  text-align: left;
}

.timeline_container.right {
  left: 27%;
  text-align: left;
}

.timeline_container::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  right: -8px;
  background: #ffffff;
  border: 2px solid #006E51;
  border-radius: 16px;
  z-index: 1;
}

.timeline_container.right::after {
  left: -8px;
}

.timeline_container::before {
  content: '';
  position: absolute;
  width: 50px;
  height: 2px;
  top: calc(50% - 1px);
  right: 8px;
  background: #006E51;
  z-index: 1;
}

.timeline_container.right::before {
  left: 8px;
}

.timeline_container .date {
  position: absolute;
  display: inline-block;
  top: calc(50% - 8px);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #006E51;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
}

.timeline_container.left .date {
  right: -110px;
}

.timeline_container.right .date {
  left: -110px;
}

.timeline_container .icon {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 9px 0;
  top: calc(50% - 20px);
  background: #F6D155;
  border: 2px solid #006E51;
  border-radius: 40px;
  text-align: center;
  font-size: 18px;
  color: #006E51;
  z-index: 1;
}

.timeline_container.left .icon {
  right: 56px;
}

.timeline_container.right .icon {
  left: 56px;
}

.timeline_container .content {
  padding: 30px 90px 30px 30px;
  background: #F6D155;
  position: relative;
  border-radius: 0 500px 500px 0;
}

.timeline_container.right .content {
  padding: 30px 30px 30px 90px;
  border-radius: 500px 0 0 500px;
}

.timeline_container .content h2 {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: normal;
  color: #006E51;
}

.timeline_container .content p {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

@media (max-width: 1250px){
  .timeline {
    margin: 0;
    max-width: 100%;
  }
  .timeline_container {
    width: 48%;
    padding: 15px 0;
  }
  .left {
    padding-right: 2em;
  }
  .timeline_container.left {
    right: 25%;
    /* width: 48%; */
  }
  .right {
    padding-left: 2%;
  }
  .timeline_container.right {
    left: 25%;
    /* width: 48%; */
  }
}

@media only screen and (max-width: 768px) {
  h1 {
    line-height: 1em;
    margin: 0;
  }
  .header {
    height: 100vh;
    margin: 0;
  }
  .title_wrapper {
    font-size: 50%;
  }

  .logo_container {
    display: none;
    margin-bottom: 0;
  }
  .container {
    height: 50vh;
  }
  .container_image {
    top: 50vh;
    background-size: 400%;
    background-position-x: 45%;
    background-position-y: center;
  }
  .container_solid .title_wrapper h1 {
    background-size: 400%;
    background-position-x: 45%;
    background-position-y: center;
  }

  .timeline {
    /* reset height fo timeline component  */
    min-height: auto;
  }
  .timeline::after {
    left: 5%;
    bottom: 0%;
  }

  .timeline_container {
    width: calc(100% - (50px + 20%));
    padding-left: 0;
  }

  .timeline_container.right {
    left: 5%;
    padding-left: 50px;
    width: calc(95% - 50px);
  }
  .timeline_container.left {
    left: 5%;
    padding-left: 50px;
    width: calc(95% - 50px);
  }
  .left {
    padding-left: 0;
    padding-right: 0;
  }
  .right {
    padding-left: 0em;
    padding-right: 0;
  }

  .timeline_container.left::after {
    left: -8px;
  }

  .timeline_container.left::before {
    left: 8px;
  }

  .timeline_container.right .date {
    left: calc(100% - 100px);
    top: calc(100% - 35px);
  }
  .timeline_container.left .date {
    right: 0;
    left: calc(100% - 110px);
    top: calc(100% - 35px);
  }

  .timeline_container.left .icon,
  .timeline_container.right .icon {
    right: auto;
    left: 146px;
  }

  .timeline_container.left .content,
  .timeline_container.right .content {
    padding: 20px 20px 20px 40px;
    border-radius: 500px 0 0 500px;
  }
}
/* timeline CSS in homepage ends here */

/* H Logo CSS ---------------------------------------------------------------------- */
.logo_container {
  width: 7em;
  aspect-ratio: 1/1;
  border: 1em solid black;
  border-radius: 50%;
  text-align: -webkit-center;
}

.logo_horizontal {
  margin: -1.5em -1.5em -1.5em -1.5em;
  /* div modified to letter h through grid columns */
  display: grid;
  grid-template-columns: 1em 1em 2em 1em 1em;
  align-content: space-around;
  justify-content: center;
}
/* horizontal black line for letter h */
.horizontal_black {
  background-color: black; 
}
/* horizontal white line for letter h */
.horizontal_white {
  background: none;
}
.black1 {
  height: 5em;
  -webkit-transform: translateY(1em);
          transform: translateY(1em);
}
.white1 {
  background-color: white;
  height: 5em;
}
.horizontal_blank {
  height: 3em;
  width: 2em;
  border: 1em solid black;
  border-bottom: 0px white;
  border-radius: 50% 50% 0 0;
  -webkit-transform: translate(-1em, 3em);
          transform: translate(-1em, 3em);
}
.black2 {
  height: 4em;
  -webkit-transform: translateY(5em);
          transform: translateY(5em);
}
.white2 {
  background-color: white;
  -webkit-transform: translateY(5em);
          transform: translateY(5em);
}
/* h logo CSS ends here */


/* Checkbox CSS modified -------------------------------------------------------------------------------------------------*/
/* label {
  display: block;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

input {
  position: absolute;
  transform: scale(0);
}

input:checked ~ .checkbox {
  transform: rotate(45deg);
  width: 20px;
  margin-left: 16px;
  border-color: #24c78e;
  border-top-color: transparent;
  border-left-color: transparent;
  border-radius: 0;
}

.checkbox {
  display: block;
  width: inherit;
  height: inherit;
  border: 3px solid #434343;
  border-radius: 6px;
  transition: all 0.375s;
} */

/* Navigation Bar */
/* .sidebar {
  background: #4f4f4f;
  flex: 1 1 15%;
  min-width: 285px;
  padding: 3em;
  margin: .5em;
  border-radius: 25px;
}

.logo {
  margin-bottom: 2em;
}

.logo img {
  width: 40px;
  border-radius: 50%;
}

.nav-item {
  color: #460d0d;
  text-decoration: none;
  font-size: 1.7rem;
  display: inline-block;
  margin-right: 2em;  
  position: relative;
}

.nav-item.contact::before {
  content: "hkarmacharya@gmail.com";
  display: block;
  position: absolute;
  background-color: gray;
  border-radius: 5px;
  bottom: 0;
  left: -100%;
  padding: 0 .5em;
  transform: scale(0);
  transition: 
    transform ease-out 150ms,
    bottom ease-out 150ms;
}
.nav-item.contact:hover::before {
  transform: scale(0.75);
  bottom: 100%;
}

@media (max-width: 746px) {
  .sidebar {
    max-width: 500px;
  }
  .logo {
    margin-bottom: 5em;
  }
  .nav-item {
    display: block;
    margin: 7vh 0;
  }
  .nav-item.contact::before {
    left: -25%;
  }
}   */

label .menu {
    position: absolute;
    right: calc(50% - 50px);
    top: -100px;
    z-index: 100;
    width: 100px;
    height: 100px;
    background: #FFF;
    border-radius: 50% 50% 50% 50%;
    transition: .5s ease-in-out;
    box-shadow: 0 0 0 0 #FFF, 0 0 0 0 #FFF;
    cursor: pointer;
    
  }
  
  label .hamburger {
    position: absolute;
    top: 135px;
    left: 50px;
    width: 30px;
    height: 5px;
    border-radius: 5px;
    background: #444440;
    display: block;
    -webkit-transform-origin: center;
    transform-origin: center;
    transition: .5s ease-in-out;
  }
  
  label .hamburger:after, label .hamburger:before {
    transition: .5s ease-in-out;
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: #444440;
  }
  
  label .hamburger:before { top: -10px; }
  
  label .hamburger:after { bottom: -10px; }
  
  label input { display: none; }
  
  label input:checked + .menu {
    box-shadow: 0 0 0 100vw #FFF, 0 0 0 100vh #FFF;
    border-radius: 0;
  }
  
  label input:checked + .menu .hamburger {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    
  }
  
  label input:checked + .menu .hamburger:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    bottom: 0;
    
  }
  
  label input:checked + .menu .hamburger:before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    top: 0;
    
  }
  
  label input:checked + .menu + ul { display: block; /*show menu when active*/ }
  
  label ul {
    list-style: none;
    z-index: 200;
    position: absolute;
    top: 100px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: none; /*Hide menu when not active*/
    transition: .25s 0s ease-in-out;
  }
  
  label a {
    margin-bottom: 1em;
    display: block;
    /* color: ; */
    text-decoration: none;
    
  }
/* Resume section CSS starts here --------------------------------------------------- */
.resume {
    width: 55em;
    margin: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 4em 2em;
    display: grid;
    grid-template-columns: 5fr 7fr;
    background: white;
  }
  
  .save-icon {
    float: right;}
  
  /* Left Column of Resume --------------------------------------------------------------------*/
  .resume > .left {
    padding: 1em;
    background: #f4f4f4;
  }
  
  /* Copy button ---------------------------------------------------------------------------- */
  .email > span {
    position: relative;
    top: 2px;
    left: 1em;
    display: none;
  }
  .email:hover > span {
    cursor: pointer;
    display: inline;
  }
  .copied {
    opacity: 0;
  }
  
  .col1-row1, .col1-row2,
  .col1-row3, .col1-row4 {
    margin-top: 1em;
    margin-left: 1em;
    display: flex;
    flex-direction: column;
  }
  
  .col1-row1-row > h1 {
    color: #444440;
  }
  .col1-row1-row > h4 {
    font-weight: lighter;
    color: #171616;
  }
  .col1-row1-row > ul,
  .col1-row4-row > ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  .col1-row1-row > ul > li {
    margin-top: 1em;
  }
  .col1-row1-row > ul > li > a, 
  .col1-row4-row > ul > li > a {
    text-decoration: none;
    color: #444440;
  }
  
  .col1-row2-row > h2, 
  .col1-row3-row > h2,
  .col1-row4-row > h2 {
    margin-top: 5px;
    color: #444440;
  }
  
  .col1-row2-row > p {
    color: #000000;
    font-weight: bold;
  }
  
  .col1-row2-row > ul >li {
    margin: 5px;
  }
  
  .col1-row1-row-row1 {
    display: grid;
    grid-template-columns: 1fr 4fr;
  }
  
  /* Grid for Language section */
  .col1-row3-row > div {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
  .dots {
    -webkit-transform: scale(2);
            transform: scale(2);
    justify-self: start;
    margin-left: 10px;
    letter-spacing: 2px;
  }
  
  .col1-row4-row > ul > li:hover {
    transition: .2s;  /* delays the hover effect */
    -webkit-transform: scale(1, 1.15);
            transform: scale(1, 1.15); /* becomes 1.05* bigger */
  }
  
  /* Right column of Resume -------------------------------------------------------------------------- */
  .right {
    padding-left: 1em;
  }
  
  .type {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    float: none;
    /* transform: translateX(-10px); */
  }
  
  .typing-demo {
    width: 9.5em;
    /* animation: name duration timing-function delay iteration-count direction; */
    -webkit-animation: typing 3.5s steps(22), blink .5s step-end infinite alternate;
            animation: typing 3.5s steps(22), blink .5s step-end infinite alternate;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-family: monospace;
    font-size: .8em;
  }
  
  @-webkit-keyframes typing {
    from {
      width: 0;
    }
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
  }
  
  @-webkit-keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  
  .col2-row1, .col2-row2, .col2-row3 {
    margin: 2em;
    display: flex;
    flex-direction: column;
    color: #444440;
    align-content: space-between;
  }
  
  .col2-row1-row {
    margin-top: 1em;
    margin-bottom: 1em;
    display: grid;
    grid-template-rows: 1fr;
    height: 50px;
  }
  .right-heading2 {
    margin-bottom: -1em;
  }
  
  .col2-row1-row > div:hover {
    transition: .2s;  /* delays the hover effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 3px;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  
  .col2-row2-row > div:hover {
    transition: .2s;  /* delays the hover effect */
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    padding-top: 5px;
    padding-left: 5px;
    box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.1), 0 0px 20px 0 rgba(0, 0, 0, 0.1);
  }
  
  .col2-row2-row-row > h2 {
    margin: 0;
    margin-top: 10px;
    font-style: italic;
    font-size: larger;
    color: #737373;
  }
  
  .col2-row2-row-row > ul {
    margin: 0;
    margin-bottom: 2em;
    color: #171616;
  }
  
  .col2-row1-row-row > h4, 
  .col2-row2-row-row > h4 {
    font-size: small;
    margin: 0px;
    color: #171616;
  }
  
  .col2-row1-row-row > p,
  .col2-row2-row-row > p {
    float: right;
    margin: 7px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    font-weight: bold;
    /* color: #737373; */
  }
  
  .col2-row1-row-row > ul > li >p,
  .col2-row2-row-row > ul > li > p {
    margin: 7px;
    font-size: 12px;
  }
  
  .col2-row1-row-row > i {
    font-size: small;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .col2-row1-row-row > h3 {
    margin-top: 8px;
    margin-bottom: 5px;
    font-size: medium;
    color: #737373;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .col2-row3-row {
    display: grid;
    grid-template-rows: 1fr;
  }
  
  .col2-row3-row {
    display: grid;
    grid-template-columns: 3fr 2fr;
  }
  .col2-row3-row-row {
    margin-bottom: 5px;
  }
  
  .col2-row3-row-row > p {
    font-size: small;
    margin: 0;
  }
  .col2-row3-row-row > p > i {
    float: right;
  }

  @media only screen and (max-width: 500px) {
    .resume {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 4em 1em 1em 1em;
      grid-template-columns: none;
    }
    .right {
      padding-left: 0;
    }
    .type, .save-icon {
      position: fixed;
      right: 1em;
    }
    .type {
      bottom: 0;
    }
    .save-icon {
      bottom: 1em;
    }
    .dit {
      padding-top: 1em;
    }
    .col2-row1, .col2-row2, .col2-row3 {
      margin: 0.5em 2em;
    }
  }
